<template>
  <div class="language-selector" :class="{ 'is-open': isOpen }">
    <button
        @click="toggleDropdown"
        class="language-selector__button"
        :aria-expanded="isOpen.toString()"
        aria-haspopup="listbox"
    >
      <span class="language-selector__current">{{ getCurrentLanguageName() }}</span>
      <span class="language-selector__icon" aria-hidden="true">▼</span>
    </button>
    <ul
        v-show="isOpen"
        class="language-selector__list"
        role="listbox"
        :aria-activedescendant="'language-option-' + selectedLanguage"
    >
      <li
          v-for="lang in languages"
          :key="lang.code"
          :id="'language-option-' + lang.code"
          class="language-selector__item"
          :class="{ 'is-selected': selectedLanguage === lang.code }"
          role="option"
          :aria-selected="(selectedLanguage === lang.code).toString()"
          @click="selectLanguage(lang.code)"
      >
        {{ translate(lang.name) }}
      </li>
    </ul>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue';
import { setLanguage, getLanguage, translate } from "@/scripts/Langue/translationService";

export default {
  setup() {
    const selectedLanguage = ref(getLanguage());
    const isOpen = ref(false);
    const languages = [
      { code: 'fr', name: 'French' },
      { code: 'en', name: 'English' },
      { code: 'de', name: 'Deutsch' }
    ];

    const toggleDropdown = () => {
      isOpen.value = !isOpen.value;
    };

    const selectLanguage = (langCode) => {
      selectedLanguage.value = langCode;
      setLanguage(langCode);
      isOpen.value = false;
    };

    const getCurrentLanguageName = () => {
      const currentLang = languages.find(lang => lang.code === selectedLanguage.value);
      return translate(currentLang ? currentLang.name : '');
    };

    const closeDropdown = (event) => {
      if (!event.target.closest('.language-selector')) {
        isOpen.value = false;
      }
    };

    onMounted(() => {
      document.addEventListener('click', closeDropdown);
    });

    onUnmounted(() => {
      document.removeEventListener('click', closeDropdown);
    });

    return {
      selectedLanguage,
      isOpen,
      languages,
      toggleDropdown,
      selectLanguage,
      getCurrentLanguageName,
      translate
    };
  }
};
</script>

<style scoped>
.language-selector {
  margin-left: 10px;
  margin-right: 10px;
  position: relative;
  width: 150px;
  font-family: 'Roboto', Arial, sans-serif;
}

.language-selector__button {
  width: 100%;
  padding: 7px 10px;
  margin: 0px 10px 0px 10px;
  background-color: rgb(255, 255, 255); /* Vert plus foncé pour un meilleur contraste */
  border: 1px solid #4cae4c;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.language-selector__button:hover {
  background-color: #4cae4c;
  border-color: #449d44;
}

.language-selector__current {
  font-weight: 500;
  color: #000000; /* Texte blanc pour un meilleur contraste */
}

.language-selector__icon {
  font-size: 0.8em;
  transition: transform 0.3s ease;
  color: #000000;
}

.is-open .language-selector__icon {
  transform: rotate(180deg);
}

.language-selector__list {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #4cae4c;
  border-radius: 8px;
  list-style: none;
  padding: 5px 0;
  margin: 0;
  max-height: 200px;
  overflow-y: auto;
  z-index: 10;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  opacity: 0;
  transform: translateY(-10px);
  transition: all 0.3s ease;
}

.is-open .language-selector__list {
  opacity: 1;
  transform: translateY(0);
}

.language-selector__item {
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  color: #333;
}

.language-selector__item:hover {
  background-color: #e8f6e8; /* Vert très clair pour le survol */
}

.language-selector__item.is-selected {
  background-color: #dff0d8; /* Vert clair pour l'élément sélectionné */
  font-weight: 500;
  color: #3c763d; /* Vert foncé pour le texte de l'élément sélectionné */
}

/* Stylisation de la barre de défilement */
.language-selector__list::-webkit-scrollbar {
  width: 6px;
}

.language-selector__list::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.language-selector__list::-webkit-scrollbar-thumb {
  background: #5cb85c;
  border-radius: 3px;
}

.language-selector__list::-webkit-scrollbar-thumb:hover {
  background: #4cae4c;
}
</style>