<template>
  <div class="site-container">
    <NavBar/>
    <div id="app" class="content-wrapper">
      <div class="app-wrapper">
        <loading/>
        <Validation/>
        <router-view></router-view>
      </div>
    </div>
    <Footer class="site-footer"/>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import Carousel from "@/components/Carousel.vue";
import Loading from "@/components/Loading.vue";
import Validation from "@/components/Validation.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: 'App',
  components: {
    Footer,
    Validation,
    Loading,
    NavBar,
    Carousel
  },
};
</script>

<style>
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.site-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* 100% de la hauteur de la fenêtre */
}

.content-wrapper {
  flex: 1 0 auto; /* Permet au contenu de s'étendre */
}
</style>