<template>
  <footer class="footer">
    <div class="footer-content">
      <div class="footer-section">
        <h3>OKONDA</h3>
        <p>Association loi 1901</p>
        <p>17 Bis grande rue, 25430 RANDEVILLERS</p>
      </div>
      <div class="footer-section">
        <h3>Contact</h3>
        <p>Email: <a href="mailto:Okonda.bureau@gmail.com">Okonda.bureau@gmail.com</a></p>
      </div>
      <div class="footer-section">
        <h3>Liens rapides</h3>
        <ul>
          <li>
            <router-link :to="'Home'" @click="closeNav">{{ translate('Home') }}</router-link>
          </li>
          <li>
            <router-link :to="'Projects'" @click="closeNav">{{ translate('Projects') }}</router-link>
          </li>
          <li>
            <router-link :to="'Activities'" @click="closeNav">{{ translate('Activities') }}</router-link>
          </li>
          <li>
            <router-link :to="'Members'" @click="closeNav">{{ translate('Members') }}</router-link>
          </li>
          <li>
            <router-link :to="'SupportUs'" class="SupportUs" @click="closeNav">{{ translate('SupportUs') }}</router-link>
          </li>
        </ul>
      </div>
      <div class="footer-section">
        <h3>Suivez-nous</h3>
        <SocialIcons></SocialIcons>
      </div>
    </div>
    <div class="footer-bottom">
      <p>&copy; {{ GetYear() }} Association OKONDA. Tous droits réservés.</p>
      <p>
        <router-link to="MentionsLegales">Mentions légales</router-link>
        <router-link to="PolitiqueConfidentialite">Politique de confidentialité</router-link>
        <router-link to="CGU">CGU</router-link>
      </p>
    </div>
  </footer>
</template>

<script>
import { ref } from 'vue'
import {translate} from "@/scripts/Langue/translationService";
import LangueSelector from "@/components/LangueSelector.vue";
import SocialIcons from "@/components/SocialIcons.vue";

export default {
  name: "NavBar",
  methods: {
    translate,
    closeNav()
    {
      document.getElementById("nav-check").checked = false;
    },
    GetYear(){return ref(new Date().getFullYear())}
  },
  components: {SocialIcons, LangueSelector},
};
</script>
<style scoped>
.footer {
  background-color: #333;
  color: #fff;
  padding: 2rem 0;
  font-family: Arial, sans-serif;
}

.footer-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.footer-section {
  flex: 1;
  min-width: 200px;
  margin-bottom: 1rem;
}

.footer-section h3 {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.footer-section ul {
  list-style: none;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 0.5rem;
}

.footer-section a {
  color: #fff;
  text-decoration: none;
}

.footer-section a:hover {
  text-decoration: underline;
}

.footer-bottom {
  text-align: center;
  margin-top: 2rem;
  padding-top: 1rem;
  border-top: 1px solid #555;
}

.footer-bottom p {
  margin: 0.5rem 0;
}

.footer-bottom a {
  color: #fff;
  text-decoration: none;
  margin: 0 0.5rem;
}

.footer-bottom a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
  }

  .footer-section {
    margin-bottom: 2rem;
  }
}
</style>