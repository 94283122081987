<template>
  <div class="nav">
    <input id="nav-check" type="checkbox">
    <div class="nav-header">
      <div class="nav-title">
        <div class="logo-container">
          <img alt="Logo" class="logo" src="@/assets/logo.png">
          <span class="brand"><a href="/">Okonda</a></span>
        </div>
      </div>
    </div>

    <div class="nav-btn">
      <label for="nav-check">
        <span></span>
        <span></span>
        <span></span>
      </label>
    </div>
    <div class="userPref">

    </div>
    <div class="nav-links">
      <div>
        <a>{{ getUserEmail() }} </a>
        <LangueSelector></LangueSelector>
      </div>

      <router-link :to="'Home'" @click="closeNav">{{ translate('Home') }}</router-link>
      <router-link :to="'Projects'" @click="closeNav">{{ translate('Projects') }}</router-link>
      <router-link :to="'Activities'" @click="closeNav">{{ translate('Activities') }}</router-link>
      <router-link :to="'Members'" @click="closeNav">{{ translate('Members') }}</router-link>
      <router-link :to="'SupportUs'" class="SupportUs" @click="closeNav">{{ translate('SupportUs') }}</router-link>
      <SocialIcons></SocialIcons>

    </div>

  </div>
</template>

<script>
import {translate} from "@/scripts/Langue/translationService";
import LangueSelector from "@/components/LangueSelector.vue";
import SocialIcons from "@/components/SocialIcons.vue";

export default {
  name: "NavBar",
  methods: {
    translate,
    closeNav()
    {
      document.getElementById("nav-check").checked = false;
    },
    getUserEmail()
    {
      let lResult = "";
      const lUserJson = localStorage.getItem('user-authenticated');
      if (lUserJson != null && lUserJson != "null")
        lResult = JSON.parse(lUserJson)['email']
      return lResult
    }
  },
  components: {SocialIcons, LangueSelector},
};
</script>

<style scoped>
.SupportUs{
  background: #42b983;
}

.userPref
{
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #efefef;
}

.nav {
  height: 50px;
  width: 100%;
  background-color: #67bb57;
  align-items: center;
  display: flex;
  font-size: 18px;
}

.nav > .nav-header {
  display: inline;
}

.nav > .nav-header > .nav-title {
  display: inline-block;
  color: #fff;
  padding: 10px 10px 10px 10px;
}

.nav > .nav-btn {
  display: inline;
}

.nav > .nav-links {
  display: inline;
  margin-left: auto;
}

.nav > .nav-links > a {
  display: inline-block;
  padding: 13px 10px 13px 10px;
  text-decoration: none;
  color: #efefef;
  align-items: center;
}

.nav > .nav-links > a:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.nav > #nav-check {
  display: none;
}

@media (max-width: 1130px) {
  .nav-links LangueSelector {
    display: block;
    padding: 15px 10px;
    text-decoration: none;
    color: #efefef;
  }
  .nav > .nav-btn {
    z-index: 3;
    display: inline-block;
    position: absolute;
    right: 0px;
    top: 0px;
  }

  .nav > .nav-btn > label {
    display: inline-block;
    width: 50px;
    height: 50px;
    padding: 13px;
  }

  .nav > .nav-btn > label:hover, .nav #nav-check:checked ~ .nav-btn > label {
    background-color: rgba(0, 0, 0, 0.3);
  }

  .nav > .nav-btn > label > span {
    display: block;
    width: 25px;
    height: 10px;
    border-top: 2px solid #eee;
  }

  .nav > .nav-links {
    position: absolute;
    display: block;
    width: 100%;
    background-image: linear-gradient(to bottom, #333 50%, transparent 50%);
    height: 0px;
    transition: all 0.3s ease-in;
    overflow-y: hidden;
    top: 50px;
    left: 0px;
    /*affiche le menu hamburger par dessus le reste*/
    z-index: 4;
    overflow-x: hidden;
  }

  .nav > .nav-links > a {
    display: block;
    width: 100%;
  }

  .nav > #nav-check:not(:checked) ~ .nav-links {
    height: 0px;
  }

  .nav > #nav-check:checked ~ .nav-links {
    height: calc(100vh - 50px);
    overflow-y: auto;
  }
}

/* Animation de déplacement du logo et du titre */
@keyframes slideInLeft {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Animation de rotation pour le logo */
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

/* Logo et titre */
.logo-container {
  display: flex;
  align-items: center;
  animation: slideInLeft 0.8s ease;
}

.logo {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  animation: rotate 1s ease 1;
}

.brand {
  font-size: 20px;
  font-weight: bold;
}

/* Liens de navigation */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 20px;
  animation: fadeInUp 0.5s ease;
}


.nav-links div {
  display: inline-block;
  margin: 0 10px 0 10px;
  align-items: center;
}

a {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.nav-links a:hover {
  text-decoration: underline;
  color: #c4e2f3; /* Bleu océan pour le survol */
}

</style>
