<template>
  <div class="carousel" aria-label="Carrousel d'images">
    <div class="swiper-container">
      <div class="swiper-wrapper">
        <div v-for="(item, index) in items" :key="index" class="swiper-slide">
          <div class="image-container">
            <img
                :src="item.image"
                :alt="item.alt"
                :style="{ marginTop: `${item.yPosition}%` }"
                loading="lazy"
                decoding="async"
                width="1920"
                height="1080"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="gradient-mask" aria-hidden="true"></div>
    <div class="swiper-pagination" role="tablist"></div>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

// Importez les images de manière asynchrone
const importImage = (name) => import(`@/assets/images/carousel/${name}.webp`);

const imagePaths = [
  {image: 'P1', alt: "Description de l'image 1", yPosition: 0},
  {image: 'P2', alt: "Description de l'image 2", yPosition: 0},
  {image: 'P3', alt: "Description de l'image 3", yPosition: 0},
  {image: 'P4', alt: "Description de l'image 4", yPosition: 0},
  {image: 'P5', alt: "Description de l'image 5", yPosition: 0},
];

export default {
  name: 'Carousel',
  setup() {
    const items = ref([]);
    const swiper = ref(null);

    const shuffleArray = (array) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    };

    onMounted(async () => {
      // Chargez et mélangez les images de manière asynchrone
      const loadedImages = await Promise.all(
          shuffleArray([...imagePaths]).map(async (item) => {
            const module = await importImage(item.image);
            return {...item, image: module.default};
          })
      );
      items.value = loadedImages;

      // Initialisez Swiper après le chargement des images
      swiper.value = new Swiper('.swiper-container', {
        loop: true,
        autoplay: {
          delay: 10000,
          disableOnInteraction: false,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        effect: 'fade',
        speed: 500,
        a11y: {
          prevSlideMessage: 'Image précédente',
          nextSlideMessage: 'Image suivante',
          paginationBulletMessage: 'Aller à l\'image {{index}}',
        },
      });
    });

    return {items};
  },
};
</script>

<style scoped>
.carousel {
  z-index: 2;
  height: 95vh;
  overflow: hidden;
  position: relative;
  transition: all 500ms;
}

.image-container {
  position: relative;
  overflow: hidden;
  height: 100%;
}

img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.gradient-mask {
  position: absolute;
  bottom: 0;
  z-index: 1;
  width: 100%;
  height: 15vh;
  background: linear-gradient(to bottom, transparent, #ffffff);
}

.swiper-button-prev,
.swiper-button-next {
  background-color: rgba(255, 255, 255, 0.5);
  color: #000;
  padding: 10px;
  border-radius: 50%;
  transition: background-color 0.3s;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  background-color: rgba(255, 255, 255, 0.8);
}

.swiper-pagination-bullet {
  background-color: #fff;
  opacity: 0.5;
}

.swiper-pagination-bullet-active {
  opacity: 1;
}
</style>