import {createRouter, createWebHistory} from 'vue-router';

const routeKeys = ['Home', 'About', 'Projects','Activities', 'Members', 'SupportUs', 'Auth','CGU','MentionsLegales','PolitiqueConfidentialite'];

// Generate routes dynamically
const routes = routeKeys.map((key) => ({
    path: ('/' + key).replace(' ', '-').normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
    component: () => import(`@/views/${key}.vue`)
}));

// Add a specific route for the root path that redirects to /Home
routes.push({
    path: '/',
    redirect: '/' + 'Home',
});

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
